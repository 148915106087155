<template lang="pug">
.supplies-table
  v-card
    .supplies-table__header
      .supplies-table__header-column {{ 'base.name' | translate }}
      .supplies-table__header-column {{ 'pages.storage.last_supply_weight' | translate }}
      .supplies-table__header-column {{ 'pages.storage.supply_remainder' | translate }}
      .supplies-table__header-column {{ 'pages.storage.supply_cost' | translate }}
      .supplies-table__header-column {{ 'base.provider' | translate }}
      .supplies-table__header-column {{ 'base.date' | translate }}
      .supplies-table__header-column

  v-table-body(
    gap
    outside-table
    :cols="5"
    :loading="loading"
    :empty="!supplies.length")
    supply-row(
      v-for="(supply, index) in supplies"
      :key="index"
      :supply="supply")

  v-card(
    v-if="!loading && showPagination")
    v-paginate(
      v-model="page"
      :page-count="pageCount"
      :click-handler="pageChangeHandler")

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import paginationMixin from '@/mixins/pagination.mixin'
  import SupplyRow from './SupplyRow.vue'

  export default {

    name: 'SuppliesTable',

    mixins: [paginationMixin],

    components: {
      SupplyRow
    },

    mounted () {
      this.setupPagination(this.fetchSupplies)
    },

    methods: {
      ...mapActions(['fetchSupplies'])
    },

    computed: {
      ...mapGetters([
        'supplies',
        'suppliesFilters'
      ]),

      filters () {
        return this.suppliesFilters
      }
    },

    watch: {
      filters () {
        this.page = 1
        this.loadData()
      }
    }
  }
</script>

<style lang="scss" scoped>

@import '@/sass/abstracts/_variables.scss';
@import '@/sass/components/_info-tooltip.scss';
@import '@/sass/base/_transition.scss';

.supplies-table {
  font-size: 1.5rem;

  &__lock-overlay {
    position: absolute;
    width: 100%;
    top: 5rem;
    bottom: 0;
    background-color: rgba($color-white, .6);
    z-index: 100;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &__header {
    height: 5.1rem;
    padding: .8rem 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &-column {
      color: $color-gray;
      overflow: hidden;
      width: 17%;

      &:nth-child(1) {
          width: 28%;
        }
      &:last-child {
        width: 5%
      }
    }
  }
}

.pagination {
  padding: 2rem;
}

</style>
